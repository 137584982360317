<template>
  <v-row :class="{ 'container-options': true, desktop: !$vuetify.display.mobile }" dense>
    <v-col
      v-for="productContainer in currentOffers"
      :key="productContainer.directusContainerId"
      cols="12"
      class="px-2 mb-1">
      <VCard
        rounded="small"
        variant="outlined"
        min-height="50px"
        :class="{
          'align-content-center mx-auto no-shadow border-theme': true,
          'active-border': pickedContainer === productContainer.directusContainerId,
        }"
        hover
        @click="selectProduct(productContainer.directusContainerId)">
        <v-row dense class="px-0" justify="center">
          <v-col cols="3" class="text-center py-6 px-0 align-content-center">
            <div class="py-0 position-relative product-icon">
              <div v-if="pickedContainer === productContainer.directusContainerId" class="pt-3">
                <v-img
                  v-if="productContainer.productSize > 10"
                  src="@/assets/icons/active-big-container.svg"
                  :alt="t('roll_off_container')"
                  height="35px" />
                <v-img
                  v-else
                  src="@/assets/icons/active-small-container.svg"
                  height="35px"
                  :alt="t('settling_container')" />
              </div>
              <div v-else class="pt-3">
                <v-img
                  v-if="productContainer.productSize > 10"
                  src="@/assets/icons/big-container.svg"
                  :alt="t('roll_off_container')"
                  height="35px" />
                <v-img
                  v-else
                  src="@/assets/icons/small-container.svg"
                  height="35px"
                  :alt="t('settling_container')" />
              </div>
              <span class="font-weight-bold pl-2 d-block position-absolute">
                {{ productContainer.size }}
              </span>
            </div>
          </v-col>
          <v-col cols="5" class="py-3 px-0">
            <span class="font-weight-bold">
              {{ productContainer.description }}
            </span>
            <p class="text-caption text-md-body-2 detail-container">
              Größe: {{ productContainer.size }}
              <br />
              Maße: {{ productContainer.dimension }}
              <br />
              Fläche: {{ productContainer.space }}
            </p>
          </v-col>
          <v-col
            cols="4"
            :class="{
              'text-right py-3 pl-0 align-content-end': true,
              'pr-2p5': pickedContainer === productContainer.directusContainerId,
              'pr-3': pickedContainer !== productContainer.directusContainerId,
            }">
            <span
              :class="{
                'font-weight-bold text-subtitle-1 text-md-h6': true,
                'text-primary': pickedContainer === productContainer.directusContainerId,
              }">
              {{ useMoneyHelper().formatCentToEuro(productContainer.unitPrice.grossPrice) }}
              &euro;
            </span>
            <p class="text-caption smaller mt-n1">
              ({{ useMoneyHelper().formatCentToEuro(productContainer.unitPrice.volumePrice) }}
              &euro;/m³)
            </p>
          </v-col>
        </v-row>
      </VCard>
    </v-col>
  </v-row>
  <v-row dense>
    <v-col cols="12" class="my-4 px-2">
      <CdeCard
        rounded="lg"
        min-height="50px"
        class="align-content-center text-white bg-secondary pl-2p5 pr-2p5">
        <v-card-text>
          <p class="font-weight-bold">{{ t('please_be_advised') }}</p>
          <p>
            {{ t('waste_should_not_exceed') }}
            <span class="font-weight-bold">{{ t('the_top_edge') }}</span>
            {{ t('of_the_container_rather_close') }}
          </p>
        </v-card-text>
      </CdeCard>
    </v-col>
    <v-col cols="12">
      <ButtonPrimary class="mt-4" width="100%" min-height="80px" @click="confirmContainerChange">
        {{ t('carry_over') }}
      </ButtonPrimary>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
const { t } = useI18n()
const offerStore = useOfferStore()
const { currentOffers } = storeToRefs(offerStore)
const sidebarStore = useSidebarStore()

const properties = defineProps<{
  selectedContainer: number
}>()

const emit = defineEmits(['containerChange'])

const { selectedContainer } = toRefs(properties)
const pickedContainer = ref<number>(selectedContainer.value)
const selectProduct = (containerId: number) => {
  pickedContainer.value = containerId
}

const confirmContainerChange = () => {
  emit('containerChange', pickedContainer.value)
  sidebarStore.closeSidebar()
}
</script>

<style scoped lang="scss">
span.position-absolute {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 10px;
  top: 16px;
  text-align: center;
  z-index: 999;
  font-size: 14px;
}
.detail-container {
  width: 110% !important;
}
@media (max-width: 959px) {
  .text-caption.smaller {
    font-size: 11px !important;
  }
}
@media (max-width: 400px) {
  .text-caption {
    font-size: 10px !important;
  }
  .text-caption.smaller {
    font-size: 9px !important;
  }
}
.container-options {
  max-height: 380px;
  overflow-y: auto;
}
.container-options.desktop {
  max-height: 65vh;
}
@media (max-height: 850px) {
  .container-options.desktop {
    max-height: 450px;
  }
}
@media (max-height: 949px) {
  .container-options.desktop {
    max-height: 520px;
  }
}
@media (max-height: 1199px) {
  .container-options.desktop {
    max-height: 58vh;
  }
}
</style>
